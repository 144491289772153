import React from 'react';
import './mapsSection.css';
import GoogleMapReact from 'google-map-react';
import {
  // faWifi,
  faHotel,
} from '@fortawesome/free-solid-svg-icons';
// library.add(fab, faCheckSquare, faCoffee)
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Container, Button } from 'reactstrap';
class MapsSection extends React.Component {
  static defaultProps = {
    center: {
      lat: 19.061456,
      lng: 72.837128,
    },
    address: {
      lat: 19.061456,
      lng: 72.837128,
    },
    zoom: 15,
  };
  constructor(props) {
    super(props);

    this.state = { directions: null };
  }

  onGoogleApiLoaded = (map, maps) => {
    // Store old reference
    const appendChild = Element.prototype.appendChild;

    // All services to catch
    const urlCatchers = [
      '/AuthenticationService.Authenticate?',
      '/QuotaService.RecordEvent?',
    ];

    // Google Map is using JSONP.
    // So we only need to detect the services removing access and disabling them by not
    // inserting them inside the DOM
    Element.prototype.appendChild = function (element) {
      const isGMapScript =
        element.tagName === 'SCRIPT' &&
        /maps\.googleapis\.com/i.test(element.src);
      const isGMapAccessScript =
        isGMapScript && urlCatchers.some(url => element.src.includes(url));

      if (!isGMapAccessScript) {
        return appendChild.call(this, element);
      }

      // Extract the callback to call it with success data
      // Only needed if you actually want to use Autocomplete/SearchBox API
      //const callback = element.src.split(/.*callback=([^\&]+)/, 2).pop();
      //const [a, b] = callback.split('.');
      //window[a][b]([1, null, 0, null, null, [1]]);

      // Returns the element to be compliant with the appendChild API
      return element;
    };
    this.infowindow = new maps.InfoWindow();
    let marker = new maps.Marker({
      map: map,
      position: new maps.LatLng(19.061456, 72.837128),
      // label: 'Empire'
    });
    marker.addListener('click', () => {
      this.infowindow.setContent('Hotel Grace Galaxy');
      this.infowindow.open(this.map, marker);
    });

    // service.nearbySearch({
    //   location: address,
    //   radius: 2000,
    //   types: ['school']
    // }, this.callback);
  };

  renderMarkers = place => {
    let marker = new this.maps.Marker({
      position: {
        lat: parseFloat(place.geometry.location.lat()),
        lng: parseFloat(place.geometry.location.lng()),
      },
      map: this.map,
      title: 'Hello World!',
    });

    marker.addListener('click', () => {
      this.infowindow.setContent('test');
      this.infowindow.open(this.map, marker);
    });
  };

  render() {
    const MapOptions = {
      streetViewControl: true,
      disableDefaultUI: false,
      mapTypeControl: true,
      styles: [
        {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [{ visibility: 'on' }],
        },
      ],
    };
    return (
      <Container>
        {/* // Important! Always set the container height explicitly */}
        <div style={{ height: '50vh', width: '100%' }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: 'AIzaSyBLz-kmHTNWi7aEhArOu9stgA_1qX5E0zY',
            }}
            defaultCenter={this.props.center}
            defaultZoom={this.props.zoom}
            options={MapOptions}
            yesIWantToUseGoogleMapApiInternals={true}
            onGoogleApiLoaded={({ map, maps }) =>
              this.onGoogleApiLoaded(map, maps)
            }
          ></GoogleMapReact>
        </div>
        <div style={{ backgroundColor: 'rgb(28,28,28)' }}>
          <Row>
            <Col style={{ color: 'white', fontFamily: 'AllerLight' }}>
              <div style={{ margin: '20px 20px' }}>
                <h3>Nearby places and attractions</h3>
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              lg={{ size: 4 }}
              sm={{ size: 12 }}
              style={{ color: 'white', fontFamily: 'AllerLight' }}
            >
              <div style={{ margin: '0px 20px' }}>
                Hospitals
                <ul>
                  <li><a href="/hotel-near-holy-family-bandra-hospital">Holy family Hospital (1.6 km)</a></li>
                  <li><a href="/lilavati-bandra-hospital-near-hotel-in-bandra">Lilavati Hospital (2.2 km)</a></li>
                  <li>Tata Memorial Hospital (7.5 km)</li>
                  <li><a href="/seven-hills-hospital-near-hotel-in-marol-andheri-bandra-mumbai">Seven Hills Hospital (5.9 km)</a></li>
                </ul>
              </div>
            </Col>
            <Col
              lg={{ size: 4 }}
              sm={{ size: 12 }}
              style={{ color: 'white', fontFamily: 'AllerLight' }}
            >
              <div style={{ margin: '0px 20px' }}>
                Eateries, bars & pubs
                <ul>
                  <li>The Daily Bar & Kitchen(48 meters) </li>
                  <li>Persian Darbar (290 meters)</li>
                  <li>Hawaiian Shack (1.6 km)</li>
                  <li>Candies (1.4 km)</li>
                </ul>
              </div>
            </Col>
            <Col
              lg={{ size: 4 }}
              sm={{ size: 12 }}
              style={{ color: 'white', fontFamily: 'AllerLight' }}
            >
              <div style={{ margin: '0px 20px' }}>
                Shopping
                <ul>
                  <li>Shoppers Stop (350 meters)</li>
                  <li>Phoenix Market City (9.5 km)</li>
                  <li>Linking Road (1 km)</li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}
export default MapsSection;
